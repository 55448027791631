import React, { Component } from "react";

import { Formik, Field, Form, ErrorMessage } from "formik";
import {
    Row,
    Col,
    Card,
    CardBody,
} from "reactstrap";


//Import Breadcrumb
class SocialIconButton extends Component {
    constructor(props) {
        super(props);
        this.node = React.createRef()
        this.state = {
        };

        this.handleClickAddMedia = this.handleClickAddMedia.bind(this)
    }

    handleClickAddMedia(){
        this.props.onClickAddMedia(this.props.media)
        return
    }

    render() {
        return (
            <React.Fragment>
                <button onClick={this.handleClickAddMedia} className="rounded-circle btn btn-outline-light mx-3">
                    <i className={`mdi mdi-${this.props.media} fs-3`} />
                </button>
            </React.Fragment>
        );
    }
}



export default SocialIconButton;
