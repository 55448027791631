import profilAyman from '../../assets/images/users/avatar-7.jpg'
import profilYoussef from '../../assets/images/users/Youssef212Agency.jpeg'
import coverYoussef from '../../assets/images/212AgencyBackGround.jpg'



/** Product 1 */
import product1 from "../../assets/images/product/img-1.png";
import product7 from "../../assets/images/product/Pro-1a.png";
import product8 from "../../assets/images/product/Pro-1b.png";

/** Product 2 */
import product2a from "../../assets/images/product/img-2.png";
import product2b from "../../assets/images/product/Pro-2a.png";
import product2c from "../../assets/images/product/Pro-2b.png";

/** Product 3 */
import product3a from "../../assets/images/product/img-3.png";
import product3b from "../../assets/images/product/Pro-4.png";
import product3c from "../../assets/images/product/Pro-4a.png";

/** Product 4 */
import product4a from "../../assets/images/product/img-4.png";
import product4b from "../../assets/images/product/Pro-12a.png";
import product4c from "../../assets/images/product/Pro-12b.png";

/** Product 5 */
import product5a from "../../assets/images/product/Pro-8.png";
import product5b from "../../assets/images/product/Pro-8a.png";
import product5c from "../../assets/images/product/Pro-8b.png";

/** Product 6 */
import product6a from "../../assets/images/product/img-6.png";
import product6b from "../../assets/images/product/Pro-17.png";
import product6c from "../../assets/images/product/Pro-17a.png";

const productsData = [
  {
    id: 1,
    image: "product1",
    name: "Half sleeve T-shirt",
    link: "#",
    category: "T-shirts",
    rating: 5,
    oldPrice: 500,
    newPrice: 405,
    isOffer: true,
    offer: 10,
    reviews: 0,
    subImage: [product1, product7, product8],
    specification: [
      { type: "Category", value: "T-shirt" },
      { type: "Brand", value: "Levis" },
      { type: "Size", value: "M" },
      { type: "Color", value: "Red" },
    ],

    features: [
      { icon: "fa fa-caret-right", type: "Fit", value: "Regular fit" },
      { icon: "fa fa-caret-right", type: "", value: "Highest quality fabric" },
      {
        icon: "fa fa-caret-right",
        type: "",
        value: "Suitable for all weather condition",
      },
      {
        icon: "fa fa-caret-right",
        type: "",
        value: "Excellent Washing and Light Fastness",
      },
    ],
    colorOptions: [
      { image: "product8", color: "Red" },
      { image: "product7", color: "Black" },
    ],
  },
  {
    id: 2,
    image: "product2",
    name: "Black color T-shirt",
    link: "#",
    category: "T-shirts",
    rating: 5,
    oldPrice: 225,
    newPrice: 175,
    isOffer: true,
    offer: 20,
    reviews: 0,
    subImage: [product2a, product2b, product2c],
    specification: [
      { type: "Category", value: "T-shirt" },
      { type: "Brand", value: "Levis" },
      { type: "Size", value: "L" },
      { type: "Color", value: "Light blue" },
    ],
    features: [
      { icon: "fa fa-caret-right", type: "Fit", value: "Regular fit" },
      { icon: "fa fa-caret-right", type: "", value: "Highest quality fabric" },
      {
        icon: "fa fa-caret-right",
        type: "",
        value: "Suitable for all weather condition",
      },
      {
        icon: "fa fa-caret-right",
        type: "",
        value: "Excellent Washing and Light Fastness",
      },
    ],
    colorOptions: [
      { image: "product2", color: "Light blue" },
      { image: "product9", color: "Black" },
    ],
  },
  {
    id: 3,
    image: "product3",
    name: "Printed T-shirt",
    link: "#",
    category: "T-shirts",
    rating: 4,
    oldPrice: 177,
    newPrice: 152,
    isOffer: true,
    offer: 14,
    reviews: 0,
    subImage: [product3a, product3b, product3c],
    specification: [
      { type: "Category", value: "T-shirt" },
      { type: "Brand", value: "Levis" },
      { type: "Size", value: "XL" },
      { type: "Color", value: "Black" },
    ],
    features: [
      { icon: "fa fa-caret-right", type: "Fit", value: "Regular fit" },
      { icon: "fa fa-caret-right", type: "", value: "Highest quality fabric" },
      {
        icon: "fa fa-caret-right",
        type: "",
        value: "Suitable for all weather condition",
      },
      {
        icon: "fa fa-caret-right",
        type: "",
        value: "Excellent Washing and Light Fastness",
      },
    ],
    colorOptions: [
      { image: "product3", color: "Black" },
      { image: "product10", color: "White" },
    ],
  },
  {
    id: 4,
    image: "product4",
    name: "Smiley Plain T-shirt",
    link: "#",
    category: "Hoodies",
    rating: 3,
    oldPrice: 150,
    newPrice: 145,
    isOffer: true,
    offer: 5,
    reviews: 0,
    subImage: [product4a, product4b, product4c],
    specification: [
      { type: "Category", value: "T-shirt" },
      { type: "Brand", value: "Levis" },
      { type: "Size", value: "M" },
      { type: "Color", value: "Blue" },
    ],
    features: [
      { icon: "fa fa-caret-right", type: "Fit", value: "Regular fit" },
      { icon: "fa fa-caret-right", type: "", value: "Highest quality fabric" },
      {
        icon: "fa fa-caret-right",
        type: "",
        value: "Suitable for all weather condition",
      },
      {
        icon: "fa fa-caret-right",
        type: "",
        value: "Excellent Washing and Light Fastness",
      },
    ],
    colorOptions: [
      { image: "product4", color: "Blue" },
      { image: "product11", color: "Black" },
    ],
  },
  {
    id: 5,
    image: "product5",
    name: "Full sleeve T-Shirt",
    link: "#",
    category: "T-shirts",
    rating: 1,
    oldPrice: 177,
    newPrice: 152,
    isOffer: false,
    offer: 0,
    reviews: 5,
    subImage: [product5a, product5b, product5c],
    specification: [
      { type: "Size", value: "S" },
      { type: "Color", value: "Coral" },
    ],
    features: [
      { icon: "fa fa-caret-right", type: "Fit", value: "Regular fit" },
      { icon: "fa fa-caret-right", type: "", value: "Highest quality fabric" },
      {
        icon: "fa fa-caret-right",
        type: "",
        value: "Suitable for all weather condition",
      },
      {
        icon: "fa fa-caret-right",
        type: "",
        value: "Excellent Washing and Light Fastness",
      },
    ],
    colorOptions: [
      { image: "product5", color: "Coral" },
      { image: "product12", color: "Black" },
    ],
  },
  {
    id: 6,
    image: "product6",
    name: "Sky blue color T-shirt",
    link: "#",
    category: "T-shirts",
    rating: 5,
    oldPrice: 200,
    newPrice: 100,
    isOffer: true,
    offer: 50,
    reviews: 10,
    subImage: [product6a, product6b, product6c],
    specification: [
      { type: "Category", value: "T-shirt" },
      { type: "Brand", value: "Levis" },
      { type: "Size", value: "L" },
      { type: "Color", value: "Green" },
    ],
    features: [
      { icon: "fa fa-caret-right", type: "Fit", value: "Regular fit" },
      { icon: "fa fa-caret-right", type: "", value: "Highest quality fabric" },
      {
        icon: "fa fa-caret-right",
        type: "",
        value: "Suitable for all weather condition",
      },
      {
        icon: "fa fa-caret-right",
        type: "",
        value: "Excellent Washing and Light Fastness",
      },
    ],
    colorOptions: [
      { image: "product6", color: "Green" },
      { image: "product13", color: "Black" },
    ],
  },
]

const recentProducts = [
  {
    id: 1,
    img: "img7",
    name: "Solid Color T-Shirt",
    link: "",
    rating: 4,
    oldPrice: 240,
    newPrice: 225,
  },
  {
    id: 2,
    img: "img4",
    name: "Smiley Plain T-shirt",
    link: "",
    rating: 3,
    oldPrice: 150,
    newPrice: 145,
  },
  {
    id: 3,
    img: "img6",
    name: "Sky blue color T-shirt",
    link: "",
    rating: 4,
    oldPrice: 138,
    newPrice: 135,
  },
]

const comments = [
  {
    id: 1,
    img: "avatar2",
    name: "Brian",
    description:
      "If several languages coalesce, the grammar of the resulting language.",
    date: "5 hrs ago",
  },
  {
    id: 2,
    img: "avatar4",
    name: "Denver",
    description:
      "To an English person, it will seem like simplified English, as a skeptical Cambridge",
    date: "07 Oct, 2019",
    childComment: [
      {
        id: 1,
        img: "avatar5",
        name: "Henry",
        description:
          "Their separate existence is a myth. For science, music, sport, etc.",
        date: "08 Oct, 2019",
      },
    ],
  },
  {
    id: 3,
    img: "Null",
    name: "Neal",
    description:
      "Everyone realizes why a new common language would be desirable.",
    date: "05 Oct, 2019",
  },
]

const discountData = [
  { label: "Less than 10%", value: 0 },
  { label: "10% or more", value: 10 },
  { label: "20% or more", value: 20 },
  { label: "30% or more", value: 30 },
  { label: "40% or more", value: 40 },
  { label: "50% or more", value: 50 },
]

const orders = [
  {
    carteId: '1',
    carteLien: 'https://www.youtube.com/',
    qrCode : null,
    infos: {
      infoHeader: {
        cover: null,
        profil: null,
      },
      infoPerso: {
        nom: 'Bougaillou',
        prenom: 'Ibrahim',
        desc: 'Dev Web',
        color: {
          bgColor: '#ffffff',
          textColor: '#000000',
        },
      },
      infoContact: {
        idContact: 'adzfzf',
        nom: 'Bougaillou',
        prenom: 'Ibrahim',
        tel: '0609844559',
        fix: '',
        email: 'Ibrahim@gmail.com',
        web: 'IbrahimBougaillou.com',
        hideShow: true,
        color: {
          prColor: '#000000',
          secColor: '#ffffff',
        },
        contactExist: true,
      },
      infoSocial: {
        socialExist: true,
        info: [
          {
            idSocial: 'efzer',
            logo: 'instagram',
            nom: 'ibrahim.insta',
            hideShow: true,
          },
          {
            idSocial: 'ert',
            logo: 'twitter',
            nom: 'ibrahim.twit',
            hideShow: true,
          },
        ],
        color: {
          prColor: '#000000',
          secColor: '#ffffff',
        },
        hideShow: true,
      },
      infoLink: [
        {
          idLink: '1',
          type: 'post',
          pic: null,
          link: 'https://app.flowcode.com/codes',
          titre: 'Title of link',
          desc: 'Desc of link',
          hideShow: true,
          color: {
            prColor: '#000000',
            secColor: '#ffffff',
          },
        },
        {
          idLink: '2',
          pic: null,
          type: 'post',
          link: 'https://app.flowcode.com/codes',
          titre: 'Title de link',
          desc: 'Desc de link',
          hideShow: true,
          color: {
            prColor: '#000000',
            secColor: '#ffffff',
          },
        },
      ],
    }
  },
  {
    carteId: '2',
    carteLien: 'https://www.youtube.com/',
    qrCode : null,
    infos: {
      infoHeader: {
        cover: null,
        profil: profilAyman,
      },
      infoPerso: {
        nom: 'El Araki',
        prenom: 'Ayman',
        desc: 'Dev Web',
        color: {
          bgColor: '#008080',
          textColor: '#0504aa',
        },
      },
      infoContact: {
        idContact: 'adzfzf',
        nom: 'El Araki',
        prenom: 'Ayman',
        tel: '0609844559',
        fix: '',
        email: 'Ayman@gmail.com',
        web: 'AymanElAraki.com',
        hideShow: true,
        color: {
          prColor: '#0504aa',
          secColor: '#008080',
        },
        contactExist: true,
      },
      infoSocial: {
        socialExist: true,
        info: [
          {
            idSocial: 'adzfzf',
            logo: 'instagram',
            nom: 'Ayman.insta',
            hideShow: true,
          },
          {
            idSocial: 'adzfzf',
            logo: 'twitter',
            nom: 'Ayman.twit',
            hideShow: true,
          },
        ],
        color: {
          prColor: '#0504aa',
          secColor: '#008080',
        },
        hideShow: true,
      },
      infoLink: [
        {
          id: '1',
          type: 'post',
          pic: null,
          link: 'https://app.flowcode.com/codes',
          titre: 'Title of link',
          desc: 'Desc of link',
          hideShow: true,
          color: {
            prColor: '#0504aa',
            secColor: '#008080',
          },
        },
        {
          id: '2',
          type: 'post',
          pic: null,
          link: 'https://app.flowcode.com/codes',
          titre: 'Title de link',
          desc: 'Desc de link',
          hideShow: true,
          color: {
            prColor: '#0504aa',
            secColor: '#008080',
          },
        },
      ],
    }
  },
  {
    carteId: '3',
    carteLien: 'https://cliq.cards/pnwHd0Av',
    qrCode : null,
    infos: {
      infoHeader: {
        cover: coverYoussef,
        profil: profilYoussef,
      },
      infoPerso: {
        nom: 'STELATE',
        prenom: 'Youssef',
        desc: 'Co-Founder & COO',
        color: {
          bgColor: '#ffffff',
          textColor: '#000000',
        },
      },
      infoContact: {
        idContact: 'adzfzf',
        nom: 'STELATE',
        prenom: 'Youssef',
        tel: '+212 681-373695',
        fix: '',
        email: 'youssef.stelate@212agency.ma',
        web: 'https://drive.google.com/file/d/1KUPQv3CvWYkgj6_1ntUy6XKbHg21hvIu/view',
        hideShow: true,
        color: {
          prColor: '#000000',
          secColor: '#ffffff',
        },
        contactExist: true,
      },
      infoSocial: {
        socialExist: true,
        info: [
          {
            idSocial: 'adzfzf',
            logo: 'instagram',
            nom: 'https://www.instagram.com/212agency.ma/',
            hideShow: true,
          },
          {
            idSocial: 'adzfzf',
            logo: 'facebook',
            nom: 'https://web.facebook.com/212agency.ma/',
            hideShow: true,
          },
          {
            idSocial: 'adzfzf',
            logo: 'linkedin',
            nom: 'https://www.linkedin.com/in/stelate/',
            hideShow: true,
          },
        ],
        color: {
          prColor: '#000000',
          secColor: '#ffffff',
        },
        hideShow: true,
      },
      infoLink: [
        {
          type: 'post',
          id: '1',
          pic: null,
          link: 'https://www.linkedin.com/company/212-agency',
          titre: 'Linkedin Entreprise',
          desc: 'Linkedin',
          hideShow: true,
          color: {
            prColor: '#000000',
            secColor: '#ffffff',
          },
        },
        {
          type: 'video',
          id: '2',
          pic: null,
          link: 'https://www.youtube.com/watch?v=TahbDflPa8E&ab_channel=SamLama',
          titre: 'Linkedin Entreprise',
          desc: 'Linkedin',
          hideShow: true,
          color: {
            prColor: '#000000',
            secColor: '#ffffff',
          },
        },
      ]
    }
  },
  {
    carteId: '4',
    carteLien: 'https://www.example.com/',
    qrCode : null,
    infos: {
      infoHeader: {
        cover: null,
        profil: null,
      },
      infoPerso: {
        nom: 'Doe',
        prenom: 'John',
        desc: 'Web Developer',
        color: {
          bgColor: '#DC143C',
          textColor: '#4169E1',
        },
      },
      infoContact: {
        idContact: 'abckde',
        nom: 'Doe',
        prenom: 'John',
        tel: '1234567890',
        fix: '9876543210',
        email: 'john.doe@example.com',
        web: 'johndoe.com',
        hideShow: true,
        color: {
          prColor: '#4169E1',
          secColor: '#DC143C',
        },
        contactExist: true,
      },
      infoSocial: {
        socialExist: true,
        info: [
          {
            idSocial: 'abcxyz',
            logo: 'linkedin',
            nom: 'john.doe',
            hideShow: true,
          },
        ],
        color: {
          prColor: '#4169E1',
          secColor: '#DC143C',
        },
        hideShow: true,
      },
      infoLink: [
        {
          idLink: '1',
          type: 'post',
          pic: null,
          link: 'https://www.example.com/blog',
          titre: 'Blog',
          desc: 'Personal blog',
          hideShow: true,
          color: {
            prColor: '#4169E1',
            secColor: '#DC143C',
          },
        },
        {
          idLink: '2',
          pic: null,
          type: 'post',
          link: 'https://www.example.com/portfolio',
          titre: 'Portfolio',
          desc: 'Web development projects',
          hideShow: true,
          color: {
            prColor: '#4169E1',
            secColor: '#DC143C',
          },
        },
      ],
    },
  },
  {
    carteId: '5',
    carteLien: 'https://www.example2.com/',
    qrCode : null,
    infos: {
      infoHeader: {
        cover: null,
        profil: null,
      },
      infoPerso: {
        nom: 'Smith',
        prenom: 'Jane',
        desc: 'Graphic Designer',
        color: {
          bgColor: '#000000',
          textColor: '#ffffff',
        },
      },
      infoContact: {
        idContact: 'xyz123',
        nom: 'Smith',
        prenom: 'Jane',
        tel: '9876543210',
        fix: '',
        email: 'jane.smith@example2.com',
        web: 'janesmith.com',
        hideShow: true,
        color: {
          prColor: '#FF0000',
          secColor: '#0504aa',
        },
        contactExist: true,
      },
      infoSocial: {
        socialExist: true,
        info: [
          {
            idSocial: 'abc123',
            logo: 'facebook',
            nom: 'jane.smith',
            hideShow: true,
          },
          {
            idSocial: 'def456',
            logo: 'pinterest',
            nom: 'janesmith',
            hideShow: true,
          },
        ],
        color: {
          prColor: '#00FF00',
          secColor: '#FFFF00',
        },
        hideShow: true,
      },
      infoLink: [
        {
          idLink: '1',
          type: 'post',
          pic: null,
          link: 'https://www.example2.com/portfolio',
          titre: 'Portfolio',
          desc: 'Graphic design projects',
          hideShow: true,
          color: {
            prColor: '#800080',
            secColor: '#FFA500',
          },
        },
        {
          idLink: '2',
          pic: null,
          type: 'post',
          link: 'https://www.example2.com/blog',
          titre: 'Blog',
          desc: 'Design tips and inspiration',
          hideShow: true,
          color: {
            prColor: '#008080',
            secColor: '#4169E1',
          },
        },
      ],
    },
  },
]


const cartData = {
  products: [
    {
      id: 1,
      img: "img1",
      name: "Half sleeve T-shirt",
      color: "Green",
      price: "450",
      data_attr: 2,
      total: 900,
    },
    {
      id: 2,
      img: "img2",
      name: "Black color T-shirt",
      color: "Black",
      price: "225",
      data_attr: 6,
      total: 225,
    },
    {
      id: 3,
      img: "img3",
      name: "Printed T-shirt",
      color: "Black",
      price: "152",
      data_attr: 2,
      total: 304,
    },
    {
      id: 4,
      img: "img4",
      name: "Smiley Plain T-shirt",
      color: "Blue",
      price: "145",
      data_attr: 2,
      total: 290,
    },
    {
      id: 5,
      img: "img5",
      name: "Full sleeve T-Shirt",
      color: "Light orange",
      price: "138",
      data_attr: 8,
      total: 138,
    },
    {
      id: 6,
      img: "img6",
      name: "Sky blue color T-shirt",
      color: "Green",
      price: "152",
      data_attr: 2,
      total: 304,
    },
  ],
  orderSummary: {
    grandTotal: "$ 1,857",
    discount: "$ 157",
    shippingCharge: "$ 25",
    estimatedTax: "$ 19.22",
    total: "$ 1744.22",
  },
}


const customerData = [
  {
    id: 1,
    username: "Stephen Rash",
    phone: "325-250-1106",
    email: "StephenRash@teleworm.us",
    address: "2470 Grove Street Bethpage, NY 11714",
    numbreCarte: "5",
    joiningDate: "2019-10-07",
  },
]

const shops = [
  {
    id: 1,
    color: "primary",
    name: "Brendle's",
    product: 112,
    balance: "13,575",
    profileLink: "#",
  },
  {
    id: 2,
    color: "warning",
    name: "Tech Hifi",
    product: 104,
    balance: "11,145",
    profileLink: "#",
  },
  {
    id: 3,
    color: "danger",
    name: "Lafayette",
    product: 126,
    balance: "12,356",
    profileLink: "#",
  },
  {
    id: 4,
    color: "success",
    name: "Packer",
    product: 102,
    balance: "11,228",
    profileLink: "#",
  },
  {
    id: 5,
    color: "info",
    name: "Nedick's",
    product: 96,
    balance: "9,235",
    profileLink: "#",
  },
  {
    id: 6,
    color: "dark",
    name: "Hudson's",
    product: 120,
    balance: "14,794",
    profileLink: "#",
  },
  {
    id: 7,
    color: "dark",
    name: "Tech Hifi",
    product: 104,
    balance: "11,145",
    profileLink: "#",
  },
  {
    id: 8,
    color: "primary",
    name: "Brendle's",
    product: 112,
    balance: "13,575",
    profileLink: "#",
  },
  {
    id: 9,
    color: "success",
    name: "Lafayette",
    product: 120,
    balance: "12,356",
    profileLink: "#",
  },
]

export {
  productsData,
  recentProducts,
  comments,
  discountData,
  orders,
  shops,
  customerData,
  cartData,
}

