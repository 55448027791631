import React, { Component } from "react";

import avatar1 from "../../../../assets/images/users/avatar-3.jpg";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

// import { Formik, Field, Form, ErrorMessage } from "formik";
import {
    Table,
    CardTitle,
    Label,
    Card,
    CardBody,
    Row,
    Col,
    Accordion,
    AccordionItem,
    AccordionBody,
    AccordionHeader,
    CardFooter,
    Form,
    Input,
    FormGroup,
} from "reactstrap";

import FormulaireBase from "./FormulaireBase";
import SocialIconButton from "./SocialIconButton";


import { CirclePicker } from 'react-color';

import SocialUnit from "./SocialUnit";

//Import Breadcrumb
class CarteSocial extends Component {
    constructor(props) {
        super(props);
        this.node = React.createRef()
        this.state = {
            open: '',
            background: '#fff',
            colors: ['#000000', '#e91e63', '#FCCB00', '#008B02'],
        };
        this.toggle = this.toggle.bind(this)
        this.handleChangeComplete = this.handleChangeComplete.bind(this)
        this.handleChangeColorSecondary = this.handleChangeColorSecondary.bind(this)
        this.handleChangeColorPrimary = this.handleChangeColorPrimary.bind(this)
    }

    handleChangeComplete = (color) => {
        this.setState({ background: color.hex });
    };

    toggle = (id) => {
        if (this.state.open === id) {
            this.setState({ open: '' })
        } else {
            this.setState({ open: id })
        }
    }

    handleChangeColorPrimary(Newcolor) {
        this.props.onChangePrimary(Newcolor)
    }
    handleChangeColorSecondary(Newcolor) {
        this.props.onChangeSecondary(Newcolor)
    }

    render() {
        return (

            <React.Fragment>

                <FormulaireBase hideShow={this.props.hideShow} setHideShow={this.props.setHideShow} title='Social Media' onDelete={this.props.onDelete} index={this.props.index}>
                    <Card>
                        <div className="d-flex flex-wrap justify-content-around p-3">
                            {this.props.listMedia.map((media, index) => {
                                return (
                                    <SocialIconButton onClickAddMedia={this.props.onClickAddMedia} media={media} key={index} />
                                )
                            })}
                        </div>
                    </Card>
                    <Accordion open={this.state.open} toggle={this.toggle} >
                        <AccordionItem>
                            <AccordionHeader targetId="1">Info</AccordionHeader>
                            <AccordionBody accordionId="1" >
                                {this.props.cardMedia.map((media, index) => {
                                    return (
                                        <SocialUnit setHideShow={this.props.setMediaHideShow} onClickDeleteMedia={this.props.onClickDeleteMedia}
                                            index={index} media={media} key={index} onChangeLink={this.props.onChangeLink}
                                        />
                                    )
                                })}
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionHeader targetId="2">Style</AccordionHeader>
                            <AccordionBody accordionId="2">
                                <Table className="table mb-0" bordered >
                                    <tbody>
                                        <tr className="table-active">
                                            <th className="text-center ">
                                                <i className='mdi mdi-card-outline' style={{ fontSize: "1.5rem" }} />
                                            </th>
                                            <td >
                                                <CirclePicker width="100%" colors={this.props.colors}
                                                    color={this.props.mediaColor.prColor}
                                                    onChangeComplete={this.handleChangeColorPrimary} />
                                            </td>
                                        </tr>
                                        <tr className="table-active">
                                            <th className="text-center ">
                                                <i className='mdi mdi-card' style={{ fontSize: "1.5rem" }} />
                                            </th>
                                            <td >
                                                <CirclePicker width="100%" colors={this.props.colors}
                                                    color={this.props.mediaColor.secColor}
                                                    onChangeComplete={this.handleChangeColorSecondary}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>

                                </Table>

                            </AccordionBody>

                        </AccordionItem>

                    </Accordion>


                </FormulaireBase>
            </React.Fragment>

        );
    }
}



export default CarteSocial;
