import React, { Component } from "react";

import avatar1 from "../../../../assets/images/users/avatar-3.jpg";


// import { Formik, Field, Form, ErrorMessage } from "formik";
import {
    Table,
    CardTitle,
    Label,
    Card,
    CardBody,
    Row,
    Col,
    Accordion,
    AccordionItem,
    AccordionBody,
    AccordionHeader,
    CardFooter,
    Form,
    Input,
    FormGroup,
} from "reactstrap";


import { CirclePicker } from 'react-color';

//Import Breadcrumb
class SocialUnit extends Component {
    constructor(props) {
        super(props);
        this.node = React.createRef()
        this.state = {
            open: '2',
            background: '#fff',
            colors: ['#000000', '#e91e63', '#FCCB00', '#008B02'],
        };
        this.toggle = this.toggle.bind(this)
        this.handleChangeComplete = this.handleChangeComplete.bind(this)

        this.handelChangeLink = this.handelChangeLink.bind(this)

        this.handleClickDeleteMedia = this.handleClickDeleteMedia.bind(this)

        this.handleHideShow = this.handleHideShow.bind(this)
    }

    handleChangeComplete = (color) => {
        this.setState({ background: color.hex });
    };

    toggle = (id) => {
        if (this.state.open === id) {
            this.setState({ open: '' })
        } else {
            this.setState({ open: id })
        }
    }

    handleClickDeleteMedia() {
        this.props.onClickDeleteMedia(this.props.index)
    }
    handelChangeLink(e) {
        this.props.onChangeLink(this.props.index, e.target.value)
    }
    handleHideShow() {
        if (this.props.media.hideShow) {
            this.props.setHideShow(this.props.index, false);
        } else {
            this.props.setHideShow(this.props.index, true);
        }
    }



    render() {

        let hideShow = this.props.media.hideShow ? 'eye' : 'eye-off'
        let opacity = this.props.media.hideShow ? '100' : '30'
        return (
            <React.Fragment>

                <Card className="border px-2 mb-2" style={{ opacity: `${opacity}%` }}>
                    <CardTitle className="h4 d-flex justify-content-between align-items-center">
                        <span className="text-capitalize">{this.props.media.logo}<i className={`mdi mdi-${this.props.media.logo} ms-2`} /></span>
                        <div>
                            <span onClick={this.handleHideShow} className="btn font-size-20"><i className={`mdi mdi-${hideShow}-outline text-dark`} /></span>
                            <span onClick={this.handleClickDeleteMedia} className="btn font-size-20"><i className="mdi mdi-trash-can text-danger" /></span>
                            {/* <button className="btn font-size-20"><i className="mdi mdi-eye-outline text-dark" /></button> */}
                        </div>
                    </CardTitle>
                    <Form>
                        <Table className="table mb-0" bordered>
                            <tbody>
                                <tr className="table-active">
                                    <th className="text-center">
                                        <Label className="form-label ">
                                            Link
                                        </Label>
                                    </th>
                                    <td >
                                        <Input
                                            name="nom"
                                            type="text"
                                            className={
                                                "form-control"
                                            }
                                            value={this.props.media.nom}
                                            onChange={this.handelChangeLink}
                                            placeholder="Nom"
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Form>
                </Card>
            </React.Fragment>
        );
    }
}



export default SocialUnit;
