import React, { Component } from "react";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";


import { Formik, Field, Form, ErrorMessage } from "formik";
import {
    Table,
    CardTitle,
    Label,
    Card,
    CardBody,
    Row,
    Col,
    Accordion,
    AccordionItem,
    AccordionBody,
    AccordionHeader,
    CardFooter,
} from "reactstrap";


import { CirclePicker } from 'react-color';

//Import Breadcrumb
class FormulaireBase extends Component {
    constructor(props) {
        super(props);
        this.node = React.createRef()
        this.state = {
            open: '',
            background: '#fff',
            colors: ['#000000', '#e91e63', '#FCCB00', '#008B02'],
        };
        this.toggle = this.toggle.bind(this)
        this.handleChangeComplete = this.handleChangeComplete.bind(this)

        this.handleClickDelete = this.handleClickDelete.bind(this)
        this.handleHideShow = this.handleHideShow.bind(this)
        this.handelLinkType = this.handelLinkType.bind(this)
    }

    handleChangeComplete = (color) => {
        this.setState({ background: color.hex });
    };

    toggle = (id) => {
        if (this.state.open === id) {
            this.setState({ open: '' })
        } else {
            this.setState({ open: id })
        }
    }

    handleClickDelete() {
        this.props.onDelete(this.props.idx);
    }

    handleHideShow() {
        if (this.props.hideShow) {
            this.props.setHideShow(this.props.idx, false)
        } else {
            this.props.setHideShow(this.props.idx, true)
        }
    }
    handelLinkType(e){
        this.props.onChangeType(this.props.idx,e.target.value)
    }

    render() {
        let hideShow = this.props.hideShow ? 'eye' : 'eye-off'
        let opacity = this.props.hideShow ? '100' : '30'
        return (

            <React.Fragment>
                <Card style={{ opacity: `${opacity}%` }}>
                    <CardBody>
                        <CardTitle className="mb-3 h4 d-flex justify-content-between align-items-center">
                            <span>{this.props.title}</span>
                            <div className="d-flex align-items-center ">
                                {this.props.type
                                    ? <div>
                                        <select className="form-select" onChange={this.handelLinkType} value={this.props.type}>
                                            <option value='post'>post</option>
                                            <option value='video'>video</option>
                                        </select>
                                    </div>
                                    : ''}
                                <span onClick={this.handleHideShow} className="btn font-size-20"><i className={`mdi mdi-${hideShow}-outline text-dark`} /></span>
                            </div>
                        </CardTitle>
                        <Row>
                            <Col className="mx-5">
                                {this.props.children}
                            </Col>
                            {/* <Col md='1' className="align-self-center">
                                <span className="p-0"><i className="mdi mdi-drag-vertical fs-1" /></span>
                            </Col> */}
                        </Row>
                        <CardFooter className="mt-3 d-flex justify-content-around align-items-center">
                            <button onClick={this.handleClickDelete} className="btn btn-outline-danger  font-size-16"><i className="mdi mdi-trash-can me-2" />Delete</button>
                        </CardFooter>
                    </CardBody>
                </Card>
            </React.Fragment>

        );
    }
}



export default FormulaireBase;
