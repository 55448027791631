import React, { Component } from "react";
import PropTypes from "prop-types";
import logo from '../../../assets/images/212Agency.png'
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
  Card,
  CardBody,
  Col,
  Row,
} from "reactstrap";

import img7 from "../../../assets/images/product/img-7.png";
import img4 from "../../../assets/images/product/img-4.png";
import QRCode from "react-qr-code";

class EcommerceOrdersModal extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }
  render() {
    // const lien = this.props.fullname ? this.props.fullname : 'azzae'

    return (
      <React.Fragment>
        <Modal
          isOpen={this.props.isOpen}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal"
          tabIndex="-1"
          toggle={this.props.toggle}
        >
          <div className="modal-content">
            {/* <ModalHeader toggle={this.props.toggle}>Order Details</ModalHeader> */}
            <ModalHeader toggle={this.props.toggle}>Scanner Code QR</ModalHeader>
            {this.props.lien
              ? <ModalBody>
                {/* <p className="mb-2">
              Product id: <span className="text-primary">#SK2540</span>
            </p>
            <p className="mb-4">
              Billing Name:{" "}
              <span className="text-primary">Neal Matthews</span>
            </p> */}

                <div className="card text-white visa-card mb-0">
                  <CardBody className="text-center" >
                    <div>
                      <QRCode value={this.props.lien} style={{boxShadow: 'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px',}} />
                    </div>
                  </CardBody>
                </div>
              </ModalBody>
              : ''}
            {/* <ModalFooter>
              <Button
                type="button"
                color="secondary"
                onClick={this.props.toggle}
              >
                Close
              </Button>
            </ModalFooter> */}
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

EcommerceOrdersModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
};

export default EcommerceOrdersModal;
