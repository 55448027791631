import React, { Component } from "react";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";


// import { Formik, Foeld, Form, ErrorMessage } from "formik";
import {
    Table,
    CardTitle,
    Label,
    Card,
    CardBody,
    Row,
    Col,
    Accordion,
    AccordionItem,
    AccordionBody,
    AccordionHeader,
    CardFooter,
    Form,
    FormGroup,
    Input,
} from "reactstrap";

import FormulaireBase from "./FormulaireBase";


import { CirclePicker } from 'react-color';

//Import Breadcrumb
class CarteContacts extends Component {
    constructor(props) {
        super(props);
        this.node = React.createRef()
        this.state = {
            open: '',
            background: '#fff',
            colors: ['#000000', '#e91e63', '#FCCB00', '#008B02'],
        };
        this.toggle = this.toggle.bind(this)
        this.handleChangeColorSecondary = this.handleChangeColorSecondary.bind(this)
        this.handleChangeColorPrimary = this.handleChangeColorPrimary.bind(this)

        this.handelChangeNom = this.handelChangeNom.bind(this)
        this.handelChangePrenom = this.handelChangePrenom.bind(this)
        this.handelChangeTel = this.handelChangeTel.bind(this)
        this.handelChangeFix = this.handelChangeFix.bind(this)
        this.handelChangeEmail = this.handelChangeEmail.bind(this)
        this.handelChangeWeb = this.handelChangeWeb.bind(this)
    }


    toggle = (id) => {
        if (this.state.open === id) {
            this.setState({ open: '' })
        } else {
            this.setState({ open: id })
        }
    }

    handleChangeColorPrimary(Newcolor) {
        this.props.onChangePrimary(Newcolor)
    }
    handleChangeColorSecondary(Newcolor) {
        this.props.onChangeSecondary(Newcolor)
    }

    handelChangeNom(e) {
        this.props.onChangeNom(e.target.value)
    }
    handelChangePrenom(e) {
        this.props.onChangePrenom(e.target.value)
    }
    handelChangeTel(e) {
        this.props.onChangeTel(e.target.value)
    }
    handelChangeFix(e) {
        this.props.onChangeFix(e.target.value)
    }
    handelChangeEmail(e) {
        this.props.onChangeEmail(e.target.value)
    }
    handelChangeWeb(e) {
        this.props.onChangeWeb(e.target.value)
    }

    render() {
        return (


            <React.Fragment>
                <FormulaireBase hideShow={this.props.infoContact.hideShow} setHideShow={this.props.setHideShow} title='Contact' onDelete={this.props.onDelete} index={this.props.index} >
                    <Accordion open={this.state.open} toggle={this.toggle}>
                        <AccordionItem>
                            <AccordionHeader targetId="1">Info</AccordionHeader>
                            <AccordionBody accordionId="1">
                                <Form>
                                    <Table className="table mb-0" bordered>
                                        <tbody>
                                            <tr className="table-active" >

                                                <td>
                                                    <FormGroup>
                                                        <Label className="form-label">
                                                            Nom
                                                        </Label>
                                                        <Input
                                                            name="nom"
                                                            type="text"
                                                            className={
                                                                "form-control"
                                                            }
                                                            placeholder="Nom"
                                                            value={this.props.infoContact.nom}
                                                            onChange={this.handelChangeNom}
                                                        />
                                                    </FormGroup>

                                                </td>
                                                <td>
                                                    <FormGroup>
                                                        <Label className="form-label">
                                                            Prenom
                                                        </Label>
                                                        <Input
                                                            name="prenom"
                                                            type="text"
                                                            className={
                                                                "form-control"
                                                            }
                                                            value={this.props.infoContact.prenom}
                                                            onChange={this.handelChangePrenom}
                                                            placeholder="Prenom"
                                                        />
                                                    </FormGroup>

                                                </td>
                                            </tr>
                                            <tr className="table-active">
                                                <td colSpan="2">
                                                    <Row>
                                                        <Col md={{ size: 1, offset: 1, }} className="align-self-center" >
                                                            <i className='mdi mdi-cellphone' style={{ fontSize: "1.5rem" }} />
                                                        </Col>
                                                        <Col md="9">
                                                            <FormGroup>
                                                                <Label className="form-label">
                                                                    Tel
                                                                </Label>
                                                                <Input
                                                                    name="Tel"
                                                                    type="text"
                                                                    className={
                                                                        "form-control"
                                                                    }
                                                                    placeholder="Tel"
                                                                    value={this.props.infoContact.tel}
                                                                    onChange={this.handelChangeTel}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </td>
                                            </tr>
                                            <tr className="table-active">
                                                <td colSpan="2">
                                                    <Row>
                                                        <Col md={{ size: 1, offset: 1, }} className="align-self-center">
                                                            <i className='mdi mdi-phone' style={{ fontSize: "1.5rem" }} />
                                                        </Col>
                                                        <Col md="9">
                                                            <FormGroup>
                                                                <Label className="form-label">
                                                                    Fix
                                                                </Label>
                                                                <Input
                                                                    name="Fix"
                                                                    type="text"
                                                                    className={
                                                                        "form-control"
                                                                    }
                                                                    value={this.props.infoContact.fix}
                                                                    onChange={this.handelChangeFix}
                                                                    placeholder="Fix"
                                                                />
                                                            </FormGroup>

                                                        </Col>
                                                    </Row>
                                                </td>
                                            </tr>
                                            <tr className="table-active">
                                                <td colSpan="2">
                                                    <Row>
                                                        <Col md={{ size: 1, offset: 1, }} className="align-self-center" >
                                                            <i className='mdi mdi-email' style={{ fontSize: "1.5rem" }} />
                                                        </Col>
                                                        <Col md="9">
                                                            <FormGroup>
                                                                <Label className="form-label">
                                                                    Email
                                                                </Label>
                                                                <Input
                                                                    name="Email"
                                                                    type="text"
                                                                    className={
                                                                        "form-control"
                                                                    }
                                                                    value={this.props.infoContact.email}
                                                                    onChange={this.handelChangeEmail}
                                                                    placeholder="Email"
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                </td>
                                            </tr>
                                            <tr className="table-active">
                                                <td colSpan="2">
                                                    <FormGroup>
                                                        <Label className="form-label">
                                                            Site Web
                                                        </Label>
                                                        <Input
                                                            name="Site_Web"
                                                            type="text"
                                                            className={
                                                                "form-control"
                                                            }
                                                            value={this.props.infoContact.web}
                                                            onChange={this.handelChangeWeb}
                                                            placeholder="Site Web"
                                                        />
                                                    </FormGroup>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Form>
                            </AccordionBody>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionHeader targetId="2">Style</AccordionHeader>
                            <AccordionBody accordionId="2">
                                <Table className="table mb-0" bordered>
                                    <tbody>
                                        <tr className="table-active">
                                            <th className="text-center ">
                                                <i className='mdi mdi-card-outline' style={{ fontSize: "1.5rem" }} />
                                            </th>
                                            <td >
                                                <CirclePicker width="100%" colors={this.props.colors}
                                                    color={this.props.contactColor.prColor}
                                                    onChangeComplete={this.handleChangeColorPrimary} />
                                            </td>
                                        </tr>
                                        <tr className="table-active">
                                            <th className="text-center ">
                                                <i className='mdi mdi-card' style={{ fontSize: "1.5rem" }} />
                                            </th>
                                            <td >
                                                <CirclePicker width="100%" colors={this.props.colors}
                                                    color={this.props.contactColor.secColor}
                                                    onChangeComplete={this.handleChangeColorSecondary}
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </AccordionBody>
                        </AccordionItem>
                    </Accordion>
                </FormulaireBase>
            </React.Fragment>

        );
    }
}



export default CarteContacts;
