import React, { Component } from "react";

import avatar1 from "../../../../assets/images/users/avatar-3.jpg";

import {
    Table,
    CardTitle,
    Label,
    Card,
    CardBody,
    Row,
    Col,
    Accordion,
    AccordionItem,
    AccordionBody,
    AccordionHeader,
    CardFooter,
    Form,
    FormGroup,
    Input,
} from "reactstrap";

import FormulaireBase from "./FormulaireBase";

import { CirclePicker } from 'react-color';

//Import Breadcrumb
class CarteLink extends Component {
    constructor(props) {
        super(props);
        this.node = React.createRef()
        this.state = {
            open: '',
            background: '#fff',
            colors: ['#000000', '#e91e63', '#FCCB00', '#008B02'],
        };
        this.toggle = this.toggle.bind(this)
        this.handleChangeComplete = this.handleChangeComplete.bind(this)

        this.handleChangeTitle = this.handleChangeTitle.bind(this)
        this.handleChangeDesc = this.handleChangeDesc.bind(this)
        this.handleChangeLink = this.handleChangeLink.bind(this)

        this.handleChangeColorSecondary = this.handleChangeColorSecondary.bind(this)
        this.handleChangeColorPrimary = this.handleChangeColorPrimary.bind(this)
        this.handelChangePic = this.handelChangePic.bind(this)
    }

    handleChangeComplete = (color) => {
        this.setState({ background: color.hex });
    };

    toggle = (id) => {
        if (this.state.open === id) {
            this.setState({ open: '' })
        } else {
            this.setState({ open: id })
        }
    }

    handelChangePic(e) {
        const img = e.target.files[0]

        this.props.onChangePic(img, this.props.idx)
    }

    handleChangeTitle(e) {
        this.props.onChangeTitle(this.props.idx, e.target.value)
    }
    handleChangeDesc(e) {
        this.props.onChangeDesc(this.props.idx, e.target.value)
    }
    handleChangeLink(e) {
        this.props.onChangeLink(this.props.idx, e.target.value)
    }
    handleChangeColorPrimary(Newcolor) {
        this.props.onChangePrimary(Newcolor, this.props.idx)
    }
    handleChangeColorSecondary(Newcolor) {
        this.props.onChangeSecondary(Newcolor, this.props.idx)
    }

    render() {
        return (

            <React.Fragment>
                <FormulaireBase hideShow={this.props.infoLink.hideShow} onChangeType={this.props.onChangeType} setHideShow={this.props.setHideShow} type={this.props.infoLink.type} title={'Link'} onDelete={this.props.onDelete} idx={this.props.idx} index={this.props.index}>
                    {this.props.infoLink.type === 'post'
                        ? <Accordion open={this.state.open} toggle={this.toggle}>
                            <AccordionItem>
                                <AccordionHeader targetId="1">Info </AccordionHeader>
                                <AccordionBody accordionId="1">
                                    <Table className="table mb-0" bordered>
                                        <tbody>
                                            <tr className="table-active">
                                                <td >
                                                    <FormGroup>
                                                        <Label className="form-label">
                                                            Link
                                                        </Label>
                                                        <Input
                                                            name="Link"
                                                            type="text"
                                                            className={
                                                                "form-control"
                                                            }
                                                            placeholder="Link"
                                                            value={this.props.infoLink.link}
                                                            onChange={this.handleChangeLink}
                                                        />
                                                    </FormGroup>
                                                </td>
                                            </tr>
                                            <tr className="table-active">
                                                <td className="d-flex">
                                                    <div className="avatar-md  me-3 border border-dark rounded-3" style={{ overflow: 'hidden' }}>
                                                        <img
                                                            src={this.props.infoLink.pic}
                                                            alt=""
                                                            className="img-fluid"
                                                        />
                                                    </div>
                                                    <div className="btns">
                                                        <button className="btn btn-success me-2 btn-sm" style={{ position: 'relative' }}>
                                                            <input className="input-file" type='file' style={{
                                                                position: 'absolute', zIndex: '2', width: '100%', height: '100%', top: 0,
                                                                left: '0',

                                                            }}
                                                                onChange={this.handelChangePic}
                                                            />
                                                            <span>Upload</span>
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr className="table-active">
                                                <td >
                                                    <FormGroup>
                                                        <Label className="form-label">
                                                            Title
                                                        </Label>
                                                        <Input
                                                            name="Title"
                                                            type="text"
                                                            className={
                                                                "form-control"
                                                            }
                                                            placeholder="Title"
                                                            value={this.props.infoLink.titre}
                                                            onChange={this.handleChangeTitle}
                                                        />
                                                    </FormGroup>
                                                </td>
                                            </tr>
                                            <tr className="table-active">
                                                <td >
                                                    <FormGroup>
                                                        <Label className="form-label">
                                                            Decription
                                                        </Label>
                                                        <Input
                                                            as="textarea"
                                                            name="Decription"
                                                            type="textarea"
                                                            className={
                                                                "form-control"
                                                            }
                                                            placeholder="Decription"
                                                            value={this.props.infoLink.desc}
                                                            onChange={this.handleChangeDesc}
                                                        />
                                                    </FormGroup>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader targetId="2">Style</AccordionHeader>
                                <AccordionBody accordionId="2">
                                    <Table className="table mb-0" bordered>
                                        <tbody>
                                            <tr className="table-active">
                                                <th className="text-center ">
                                                    <i className='mdi mdi-card-outline' style={{ fontSize: "1.5rem" }} />
                                                </th>
                                                <td >
                                                    <CirclePicker width="100%" colors={this.props.colors}
                                                        onChangeComplete={this.handleChangeColorPrimary}
                                                        color={this.props.color.prColor} />
                                                </td>
                                            </tr>
                                            <tr className="table-active">
                                                <th className="text-center ">
                                                    <i className='mdi mdi-card' style={{ fontSize: "1.5rem" }} />
                                                </th>
                                                <td >
                                                    <CirclePicker width="100%" colors={this.props.colors}
                                                        onChangeComplete={this.handleChangeColorSecondary}
                                                        color={this.props.color.secColor}
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>

                                    </Table>
                                </AccordionBody>
                            </AccordionItem>
                        </Accordion>
                        : <Table className="table mb-0" bordered>
                        <tbody>
                            <tr className="table-active">
                                <td >
                                    <FormGroup>
                                        <Label className="form-label">
                                            Link
                                        </Label>
                                        <Input
                                            name="Link"
                                            type="text"
                                            className={
                                                "form-control"
                                            }
                                            placeholder="Link"
                                            value={this.props.infoLink.link}
                                            onChange={this.handleChangeLink}
                                        />
                                    </FormGroup>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                    }
                </FormulaireBase>
            </React.Fragment>


        );
    }
}



export default CarteLink;
