import React, { Component } from "react";
import PropTypes from "prop-types";
import logo from '../../../assets/images/212Agency.png'
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
  Card,
  CardBody,
  Col,
  Row,
} from "reactstrap";

import img7 from "../../../assets/images/product/img-7.png";
import img4 from "../../../assets/images/product/img-4.png";

class EcommerceOrdersModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {

    return (
      <React.Fragment>
        <Modal
          isOpen={this.props.isOpen}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal"
          tabIndex="-1"
          toggle={this.props.toggle}
        >
          <div className="modal-content">
            {/* <ModalHeader toggle={this.props.toggle}>Order Details</ModalHeader> */}
            <ModalHeader toggle={this.props.toggle} />
            <ModalBody>
              {/* <p className="mb-2">
                Product id: <span className="text-primary">#SK2540</span>
              </p>
              <p className="mb-4">
                Billing Name:{" "}
                <span className="text-primary">Neal Matthews</span>
              </p> */}

              <div className="card bg-primary text-white visa-card mb-0">
                <CardBody >
                  <div>



                    <div>
                      {/* <i className="bx bx-chip h1 text-warning" /> */}
                      <img src={logo} className="avatar-sm" alt="" />
                    </div>
                  </div>

                  <div className="mt-5">
                    <br />
                  </div>

                  <div className="mt-5">
                    <h5 className="float-end text-white mb-0">{this.props.fullname}</h5>
                  </div>
                </CardBody>
              </div>
            </ModalBody>
            {/* <ModalFooter>
              <Button
                type="button"
                color="secondary"
                onClick={this.props.toggle}
              >
                Close
              </Button>
            </ModalFooter> */}
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

EcommerceOrdersModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
};

export default EcommerceOrdersModal;
