import React, { Component } from "react";

// import { Formik, Field, Form, ErrorMessage } from "formik";
import {
    Row,
    Col,
    Card,
    CardBody,
    Form, FormGroup, Label, Input,
} from "reactstrap";


//Import Breadcrumb
class CarteInfoPerso extends Component {
    constructor(props) {
        super(props);
        this.node = React.createRef()
        this.state = {
        };

        this.handelChangeInputNom = this.handelChangeInputNom.bind(this)
        this.handelChangeInputPrenom = this.handelChangeInputPrenom.bind(this)
        this.handelChangeInputDesc = this.handelChangeInputDesc.bind(this)
    }

    handelChangeInputNom(e) {
        this.props.onChangeInputNom(e.target.value)
    }
    handelChangeInputPrenom(e) {
        this.props.onChangeInputPrenom(e.target.value)
    }
    handelChangeInputDesc(e) {
        this.props.onChangeInputDesc(e.target.value)
    }

    render() {


        return (
            <React.Fragment>
                <Form>
                    <Card>
                        <CardBody>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Input
                                            as='input'
                                            name="nom"
                                            type="text"
                                            className={
                                                "form-control"
                                            }
                                            placeholder="Nom"
                                            value={this.props.info.nom}
                                            onChange={this.handelChangeInputNom}
                                        />
                                    </FormGroup>


                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Input
                                            name="Prenom"
                                            type="text"
                                            className={
                                                "form-control"
                                            }
                                            placeholder="Prénom"
                                            value={this.props.info.prenom}
                                            onChange={this.handelChangeInputPrenom}
                                        />
                                    </FormGroup>


                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    <Card>
                        <CardBody className="text-center">
                            <FormGroup>
                                <Input
                                    name="desc"
                                    type="textarea"
                                    className={
                                        "form-control"
                                    }
                                    placeholder="desc"
                                    value={this.props.info.desc}
                                    onChange={this.handelChangeInputDesc}
                                />
                            </FormGroup>

                        </CardBody>
                    </Card>
                </Form>
            </React.Fragment>
        );
    }
}



export default CarteInfoPerso;
