import React, { Component } from "react";
import profil_vide from '../../../../assets/images/profile-vide.webp'
import avatar1 from "../../../../assets/images/users/avatar-2.jpg";

import cover from '../../../../assets/images/crypto/bg-ico-hero.jpg'

import {
    Card,
    CardBody,
} from "reactstrap";

import './styleCss.css'



//Import Breadcrumb
class CarteHeader extends Component {
    constructor(props) {
        super(props);
        this.node = React.createRef()
        this.state = {

        };
        this.handelChangeProfil = this.handelChangeProfil.bind(this)
        this.handelChangeCover = this.handelChangeCover.bind(this)
    }

    handelChangeProfil(e) {
        const img = e.target.files[0]
        this.props.onChangeProfil(img)
    }
    handelChangeCover(e) {
        const img = e.target.files[0]
        this.props.onChnageCover(img)
    }

    render() {


        return (
            <React.Fragment>
                <Card>
                    <CardBody className="d-flex justify-content-center align-items-center cardBackgound py-5" style={{
                        backgroundImage: `url(${this.props.infoHeader.cover})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center',
                        height: 300,
                    }} >
                        <button className="btn btn-primary rounded-circle"
                            style={{ position: 'absolute', right: "2%", top: '2%' }}
                        >
                            <input className="input-file" type='file' cursor='pointer' style={{
                                position: 'absolute', zIndex: '2', width: '100%', height: '100%',
                                cursor: 'pointer', left: '0',
                            }} onChange={this.handelChangeCover} />
                            <i className="mdi mdi-pencil fs-2" />
                        </button>
                        <div className="avatar-xl" style={{ position: 'relative' }}>
                            <button className="btn btn-primary rounded-circle p-0 "
                                style={{ position: 'absolute', left: "0", top: '2%', zIndex: '1', height: '2rem', width: '2rem', overflow: 'visible', }}
                            >
                                <input className="input-file" type='file' style={{
                                    position: 'absolute', zIndex: '2', width: '100%', height: '100%',
                                    left: '0',

                                }}
                                    onChange={this.handelChangeProfil
                                    } />


                                <i className="mdi mdi-pencil fs-4" />
                            </button>
                            <div className="img-display-after">
                                {this.props.infoHeader.profil
                                    ? <img
                                        src={this.props.infoHeader.profil}
                                        alt=""
                                        className="img-fluid"
                                    />
                                    : <img
                                        src={profil_vide}
                                        alt=""
                                        className="img-fluid "
                                    />
                                }
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}



export default CarteHeader;
