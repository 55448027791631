import React, { Component } from "react";
import profil_vide from '../../../../assets/images/profile-vide.webp'
import logo from '../../../../assets/images/212Agency.png'
import './yourCard.css'
import {
    CardFooter,
} from "reactstrap";
import ReactPlayer from "react-player";

import profilYoussef from '../../../../assets/images/users/Youssef212Agency.jpeg'
import coverYoussef from '../../../../assets/images/212AgencyBackGround.jpg'

class YourCard extends Component {
    constructor(props) {
        super(props);
        this.node = React.createRef();
        // const storedState = localStorage.getItem('myState');
        const storedState = {
            carteId: '3',
            carteLien: 'https://cliq.cards/pnwHd0Av',
            infos: {
                infoHeader: {
                    cover: coverYoussef,
                    profil: profilYoussef,
                },
                infoPerso: {
                    nom: 'STELATE',
                    prenom: 'Youssef',
                    desc: 'Co-Founder & COO',
                    color: {
                        bgColor: '#ffffff',
                        textColor: '#000000',
                    },
                },
                infoContact: {
                    idContact: 'adzfzf',
                    nom: 'STELATE',
                    prenom: 'Youssef',
                    tel: '+212 681-373695',
                    fix: '',
                    email: 'youssef.stelate@212agency.ma',
                    web: 'https://drive.google.com/file/d/1KUPQv3CvWYkgj6_1ntUy6XKbHg21hvIu/view',
                    hideShow: true,
                    color: {
                        prColor: '#000000',
                        secColor: '#ffffff',
                    },
                    contactExist: true,
                },
                infoSocial: {
                    socialExist: true,
                    info: [
                        {
                            idSocial: 'adzfzf',
                            logo: 'instagram',
                            nom: 'https://www.instagram.com/212agency.ma/',
                            hideShow: true,
                        },
                        {
                            idSocial: 'adzfzf',
                            logo: 'facebook',
                            nom: 'https://web.facebook.com/212agency.ma/',
                            hideShow: true,
                        },
                        {
                            idSocial: 'adzfzf',
                            logo: 'linkedin',
                            nom: 'https://www.linkedin.com/in/stelate/',
                            hideShow: true,
                        },
                    ],
                    color: {
                        prColor: '#000000',
                        secColor: '#ffffff',
                    },
                    hideShow: true,
                },
                infoLink: [
                    {
                        type: 'post',
                        id: '1',
                        pic: null,
                        link: 'https://www.linkedin.com/company/212-agency',
                        titre: 'Linkedin Entreprise',
                        desc: 'Linkedin',
                        hideShow: true,
                        color: {
                            prColor: '#000000',
                            secColor: '#ffffff',
                        },
                    },
                    {
                        type: 'video',
                        id: '2',
                        pic: null,
                        link: 'https://www.youtube.com/watch?v=TahbDflPa8E&ab_channel=SamLama',
                        titre: 'Linkedin Entreprise',
                        desc: 'Linkedin',
                        hideShow: true,
                        color: {
                            prColor: '#000000',
                            secColor: '#ffffff',
                        },
                    },
                ]
            }
        }
        // this.state = JSON.parse(storedState)
        this.state = storedState
    }






    render() {

console.log(this.state)
        return (
            <React.Fragment>
                {/**d-flex flex-column justify-content-start */}
                <div className="your-card " style={{ backgroundColor: this.state.infos.infoPerso.color.bgColor }}>
                    <div className="your-card-header mb-5"
                        style={{ backgroundImage: `url(${this.state.infos.infoHeader.cover})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center' }}
                    >
                        <div className="avatar-xxl profil-pic">
                            <div className="img-display-after">
                                {
                                    <img
                                        src={this.state.infos.infoHeader.profil ? this.state.infos.infoHeader.profil : profil_vide}
                                        alt=""
                                        className="img-fluid "
                                    />
                                }
                            </div>
                        </div>
                    </div>
                    <div className="your-card-content mx-auto px-4 py-3 ">
                        {/* <div className="youtube-box">
                            <ReactPlayer className='video' url='https://www.youtube.com/watch?v=cLRztK1zE6s&ab_channel=SamiHassanOfficial' controls width='100%' height='250px' />
                        </div> */}
                        <div className="your-carte-header">
                            <h2 className="text-center mt-1 ">
                                <span style={{ color: this.state.infos.infoPerso.color.textColor }}>{this.state.infos.infoPerso.nom}</span>{" "}
                                <span style={{ color: this.state.infos.infoPerso.color.textColor }}>{this.state.infos.infoPerso.prenom}</span>
                            </h2>
                            <div className="text-center mt-3 bio-text"><p style={{ color: this.state.infos.infoPerso.color.textColor }}>{this.state.infos.infoPerso.desc}</p></div>
                        </div>
                        {this.state.infos.infoContact.contactExist
                            ? this.state.infos.infoContact.hideShow
                                ? <div className="your-card-contact mb-3 d-flex flex-column gap-3" >
                                    <div className=" d-flex justify-content-center gap-3">
                                        {this.state.infos.infoContact.tel ? <a href={`tel:${this.state.infos.infoContact.tel}`} className="p-1"><i style={{ color: this.state.infos.infoContact.color.prColor }} className="mdi mdi-phone" /></a> : null}
                                        {this.state.infos.infoContact.fix ? <a href={`tel:${this.state.infos.infoContact.fix}`} className="btn p-1"><i style={{ color: this.state.infos.infoContact.color.prColor }} className="mdi mdi-phone-classic" /></a> : null}
                                        {this.state.infos.infoContact.email ? <a href={`mailto:${this.state.infos.infoContact.email}`} className="btn p-1"><i style={{ color: this.state.infos.infoContact.color.prColor }} className="mdi mdi-email" /></a> : null}
                                        {this.state.infos.infoContact.web ? <a href={this.state.infos.infoContact.web} target="_blank" rel="noopener noreferrer" className="btn p-1"><i style={{ color: this.state.infos.infoContact.color.prColor }} className="mdi mdi-web" /></a> : null}
                                    </div>
                                    <button className="btn"
                                        style={{ backgroundColor: this.state.infos.infoContact.color.prColor, color: this.state.infos.infoContact.color.secColor }}>
                                        <i className="mdi mdi-human-greeting-proximity me-2 rounded-3" style={{ color: this.state.infos.infoContact.color.secColor }} />
                                        Enregistrer le Contact
                                    </button>
                                </div>
                                : ''
                            : ''
                        }

                        {this.state.socialHideShow
                            ? <div className="your-card-social d-flex flex-wrap justify-content-around mb-3">
                                {this.state.infos.infoSocial.info.map((media, index) => {
                                    return (
                                        media.hideShow
                                            ? <a href={media.nom} target="_blank" rel="noopener noreferrer" key={index} className="btn px-3 rounded-3 social-media-btn"
                                                style={{ backgroundColor: this.state.infos.infoSocial.color.secColor, border: `${this.state.infos.infoSocial.color.prColor} 3px solid` }}
                                            >
                                                <i className={`mdi mdi-${media.logo} social-media-icon`}
                                                    style={{ color: this.state.infos.infoSocial.color.prColor }}
                                                />
                                            </a>
                                            : ''
                                    )
                                })}
                            </div>
                            : ''
                        }


                        {this.state.infos.infoLink.map((link, index) => {
                            return (
                                link.hideShow
                                    ? link.type === 'post'
                                        ? <a href={link.link} target="_blank" rel="noopener noreferrer" >
                                            <div style={{
                                                border: `1px solid ${link.color.prColor}`,
                                                backgroundColor: link.color.secColor, width: '100%',
                                            }} key={index} className="p-1 your-card-link mb-3 p-2">
                                                <div className="d-flex  justify-content-around align-items-center gap-1 ">
                                                    <div className="align-self-center">
                                                        <div className="avatar-sm  rounded-3 link-pic">
                                                            <img
                                                                src={link.pic ? link.pic : profil_vide}
                                                                alt=""
                                                                className="img-fluid rounded-3  "
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="d-flex flex-column  " style={{ width: "75%" }}>
                                                        <div style={{ color: link.color.prColor }} className="fw-bold">{link.titre ? link.titre : 'Ttile'}</div>
                                                        <div style={{ color: link.color.prColor, wordWrap: 'break-word', }}>{link.desc ? link.desc : 'Description'}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                        : <div className="youtube-box">
                                            <ReactPlayer className='video' url={link.link} controls width='100%' height='250px' />
                                        </div>
                                    : ''
                            )
                        })}

                    </div>
                    <CardFooter className="your-carte-footer text-center">
                    <img src={logo} className="avatar-lg" alt="" />
                    </CardFooter>
                </div>
            </React.Fragment>
        )
    }
}



export default YourCard;
