import React, { Component } from "react";
import { v4 as uuid } from 'uuid';
import './styleCss.css'

import CropLink from "./crop/CropLink";
import CropEasy from "./crop/CropEasy";
import CropEasyCover from "./crop/CropCover";

import avatar1 from "../../../../assets/images/users/avatar-3.jpg";


import { Link } from "react-router-dom";

import CarteInfoPerso from "./InfoPerso";
import CarteHeader from "./CarteHeader";
import CarteContacts from "./CarteContacts";
import CarteLink from "./CarteLink";
import CarteSocial from "./CatreSocial";
import CartePreview from "./CartPreview";


import { CirclePicker } from 'react-color';

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Row,
    Modal,
    Badge,
    ModalHeader,
    ModalBody,
    Label,
    Table,
} from "reactstrap";

import { Formik, Field, Form, ErrorMessage } from "formik";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";


class AjouterOrder extends Component {
    constructor(props) {
        super(props);
        this.node = React.createRef();
        const storedState = localStorage.getItem('myState');
        this.state = storedState ? JSON.parse(storedState) : {
            pageTitle: 'Ajouter',
            openCropCover: false,
            openCropLink: { stat: false, index: null },
            openCrop: false,
            listMedia: ['instagram', 'twitter', 'linkedin', 'youtube', 'facebook'],
            socialHideShow: true,
            mediaColor: {
                primary: '#000000',
                secondary: '#ffffff',
            },
            background: '#fff',
            colors: ['#000000', '#ffffff', '#FF0000', '#0504aa', '#00FF00', '#FFFF00', '#800080', '#FFA500', '#008080', '#4169E1', '#DC143C'],
            infos: {
                infoSocial: {
                    socialExist: false,
                    info: [],
                    color: {
                        prColor: '#000000',
                        secColor: '#ffffff',
                    },
                    hideShow: true,
                },
                infoHeader: {
                    profil: null,
                    cover: null,
                },
                infoPerso: {
                    nom: '',
                    prenom: '',
                    desc: '',
                    color: {
                        bgColor: '#ffffff',
                        textColor: '#000000',
                    },
                },
                infoLink: [],
                infoContact: {
                    nom: '',
                    prenom: '',
                    tel: '',
                    fix: '',
                    email: '',
                    web: '',
                    hideShow: true,
                    color: {
                        bgColor: '#ffffff',
                        textColor: '#000000',
                    },
                    contactExist: false,
                }
            },


        };


        this.handleChangeCompleteColor = this.handleChangeCompleteColor.bind(this)
        this.handleChangeCompleteFontColor = this.handleChangeCompleteFontColor.bind(this)

        this.handeleChangeContactPrimaryColor = this.handeleChangeContactPrimaryColor.bind(this)
        this.handeleChangeContactSecondaryColor = this.handeleChangeContactSecondaryColor.bind(this)
        this.handeleChangeMediaPrimaryColor = this.handeleChangeMediaPrimaryColor.bind(this)
        this.handeleChangeMediaSecondaryColor = this.handeleChangeMediaSecondaryColor.bind(this)
        this.handeleChangeLinkPrimaryColor = this.handeleChangeLinkPrimaryColor.bind(this)
        this.handeleChangeLinkSecondaryColor = this.handeleChangeLinkSecondaryColor.bind(this)

        this.handelChangeInputNom = this.handelChangeInputNom.bind(this)
        this.handelChangeInputPrenom = this.handelChangeInputPrenom.bind(this)
        this.handelChangeInputDesc = this.handelChangeInputDesc.bind(this)

        this.handelChangeLinkTitle = this.handelChangeLinkTitle.bind(this)
        this.handelChangeLinkDesc = this.handelChangeLinkDesc.bind(this)
        this.handelChangeLink = this.handelChangeLink.bind(this)

        this.handelClickerAddContact = this.handelClickerAddContact.bind(this)
        this.handelClickerAddSocial = this.handelClickerAddSocial.bind(this)
        this.handelClickerAddLinks = this.handelClickerAddLinks.bind(this)

        this.handelClickerDeleteContact = this.handelClickerDeleteContact.bind(this)
        this.handelClickerDeleteSocial = this.handelClickerDeleteSocial.bind(this)
        this.handelClickerDeleteLinks = this.handelClickerDeleteLinks.bind(this)

        this.handelClickerAddMedia = this.handelClickerAddMedia.bind(this)
        this.handelClickerDeleteMedia = this.handelClickerDeleteMedia.bind(this)

        this.handelChangeContactNom = this.handelChangeContactNom.bind(this)
        this.handelChangeContactPrenom = this.handelChangeContactPrenom.bind(this)
        this.handelChangeContactTel = this.handelChangeContactTel.bind(this)
        this.handelChangeContactFix = this.handelChangeContactFix.bind(this)
        this.handelChangeContactEmail = this.handelChangeContactEmail.bind(this)
        this.handelChangeContactWeb = this.handelChangeContactWeb.bind(this)

        this.handelChangeMediaLink = this.handelChangeMediaLink.bind(this)

        this.handelClickSave = this.handelClickSave.bind(this)

        this.handelImgProfile = this.handelImgProfile.bind(this)
        this.handelImgCover = this.handelImgCover.bind(this)

        this.setOpenCrop = this.setOpenCrop.bind(this)
        this.setProfilPhoto = this.setProfilPhoto.bind(this)
        this.setOpenCropCover = this.setOpenCropCover.bind(this)
        this.setCoverPhoto = this.setCoverPhoto.bind(this)
        this.handelImgLinkPic = this.handelImgLinkPic.bind(this)
        this.setOpenCropLink = this.setOpenCropLink.bind(this)
        this.setLinkPhoto = this.setLinkPhoto.bind(this)

        this.setContactHideShow = this.setContactHideShow.bind(this)
        this.setSocialHideShow = this.setSocialHideShow.bind(this)
        this.setMediaHideShow = this.setMediaHideShow.bind(this)
        this.setLinkHideShow = this.setLinkHideShow.bind(this)

        this.handelOnDragEnd = this.handelOnDragEnd.bind(this)
        this.handleChangeTypeLink = this.handleChangeTypeLink.bind(this)
    }
    handelOnDragEnd = (results) => {
        const { source, destination, type } = results

        if (!destination) return;

        if (
            source.droppableId === destination.droppableId &&
            source.index === destination.index
        ) return;

        if (type === 'group') {
            const reordered = [...this.state.infos.infoLink]

            const sourceIndex = source.index
            const destinationIndex = destination.index

            const [remove] = reordered.splice(sourceIndex, 1)
            reordered.splice(destinationIndex, 0, remove)

            return this.setState((prevState) => ({
                infos: {
                    ...prevState.infos,
                    infoLink: reordered,
                },
            }));
        }


    };


    setOpenCrop = (stat) => {
        this.setState({ openCrop: stat })
    }
    setOpenCropCover = (stat) => {
        this.setState({ openCropCover: stat })
    }
    setOpenCropLink = (stat, index) => {
        this.setState({
            openCropLink: {
                stat: stat,
                index: index,
            }
        })
    }

    //Contact
    setContactHideShow(index, stat) {
        this.setState({
            infos: {
                ...this.state.infos,
                infoContact: {
                    ...this.state.infos.infoContact,
                    hideShow: stat,
                }
            }
        });
    }
    handelClickerAddContact() {
        if (!this.state.infos.infoContact.contactExist) {
            this.setState({
                infos: {
                    ...this.state.infos,
                    infoContact: {
                        ...this.state.infos.infoContact,
                        contactExist: true,
                    }
                },

            });
        }
    }
    handelClickerDeleteContact(idx) {
        if (this.state.infos.infoContact.contactExist) {
            this.setState({

                infos: {
                    ...this.state.infos,
                    infoContact: {
                        nom: '',
                        prenom: '',
                        tel: '',
                        fix: '',
                        email: '',
                        web: '',
                        hideShow: true,
                        color: '#000000',
                        contactExist: false,
                    },
                }

            });
            return
        } return
    }
    handeleChangeContactPrimaryColor = (NewColor) => {
        this.setState({
            infos: {
                ...this.state.infos,
                infoContact: {
                    ...this.state.infos.infoContact,
                    color: {
                        ...this.state.infos.infoContact.color,
                        prColor: NewColor.hex,
                    },
                }
            },
        });

    }
    handeleChangeContactSecondaryColor = (NewColor) => {
        this.setState({
            infos: {
                ...this.state.infos,
                infoContact: {
                    ...this.state.infos.infoContact,
                    color: {
                        ...this.state.infos.infoContact.color,
                        secColor: NewColor.hex,
                    },
                }
            },
        });

    }
    handelChangeContactNom(NewContact) {
        this.setState({
            infos: {
                ...this.state.infos,
                infoContact: {
                    ...this.state.infos.infoContact,
                    nom: NewContact,
                }
            }
        });
    }
    handelChangeContactPrenom(NewContact) {
        this.setState({
            infos: {
                ...this.state.infos,
                infoContact: {
                    ...this.state.infos.infoContact,
                    prenom: NewContact,
                }
            }
        });
    }
    handelChangeContactTel(NewContact) {
        this.setState({
            infos: {
                ...this.state.infos,
                infoContact: {
                    ...this.state.infos.infoContact,
                    tel: NewContact,
                }
            }
        });
    }
    handelChangeContactFix(NewContact) {
        this.setState({
            infos: {
                ...this.state.infos,
                infoContact: {
                    ...this.state.infos.infoContact,
                    fix: NewContact,
                }
            }
        });
    }
    handelChangeContactEmail(NewContact) {
        this.setState({
            infos: {
                ...this.state.infos,
                infoContact: {
                    ...this.state.infos.infoContact,
                    email: NewContact,
                }
            }
        });
    }
    handelChangeContactWeb(NewContact) {
        this.setState({
            infos: {
                ...this.state.infos,
                infoContact: {
                    ...this.state.infos.infoContact,
                    web: NewContact,
                }
            }
        });
    }

    //Social
    setSocialHideShow(index, stat) {
        this.setState({ socialHideShow: stat })
    }
    handelClickerAddMedia(media) {
        const infoSocial = this.state.infos.infoSocial.info;
        const logoExists = infoSocial.some(item => item.logo === media);
        if (!logoExists) {
            const newItem = { logo: media, nom: '', hideShow: true };
            this.setState({
                infos: {
                    ...this.state.infos,
                    infoSocial: {
                        ...this.state.infos.infoSocial,
                        info: [newItem, ...infoSocial],
                    },
                },

            });
        }
    }
    handelClickerDeleteMedia(index) {
        const updatedCardMedia = [...this.state.infos.infoSocial.info];
        updatedCardMedia.splice(index, 1);
        this.setState({
            infos: {
                ...this.state.infos,
                infoSocial: {
                    ...this.state.infos.infoSocial,
                    info: updatedCardMedia,
                },
            },

        });
        return
    }
    handelClickerAddSocial() {
        if (!this.state.infos.infoSocial.socialExist) {

            this.setState({
                infos: {
                    ...this.state.infos,
                    infoSocial: {
                        ...this.state.infos.infoSocial,
                        socialExist: true,
                    }
                },

            });
        }
    }
    handelClickerDeleteSocial(idx) {
        if (this.state.infos.infoSocial.socialExist) {
            this.setState({
                infos: {
                    ...this.state.infos,
                    infoSocial: {
                        socialExist: false,
                        info: [],
                        color: {
                            prColor: '#000000',
                            secColor: '#ffffff',
                        },
                        hideShow: true,
                    }
                },



            });
            return
        } return
    }
    handeleChangeMediaPrimaryColor = (NewColor) => {
        this.setState({
            infos: {
                ...this.state.infos,
                infoSocial: {
                    ...this.state.infos.infoSocial,
                    color: {
                        ...this.state.infos.infoSocial.color,
                        prColor: NewColor.hex,
                    }
                }
            }
        });
    }
    handeleChangeMediaSecondaryColor = (NewColor) => {
        this.setState({
            infos: {
                ...this.state.infos,
                infoSocial: {
                    ...this.state.infos.infoSocial,
                    color: {
                        ...this.state.infos.infoSocial.color,
                        secColor: NewColor.hex,
                    }
                }
            }
        });
    }
    handelChangeMediaLink(index, NewLink) {
        this.setState(prevState => {
            const updatedCardMedia = [...prevState.infos.infoSocial.info];
            updatedCardMedia[index]['nom'] = NewLink;

            return {
                infos: {
                    ...this.state.infos,
                    infoSocial: {
                        ...this.state.infos.infoSocial,
                        info: updatedCardMedia,
                    },
                },
            };
        });
    }
    setMediaHideShow(index, stat) {
        this.setState(prevState => {
            const updatedCardMedia = [...prevState.infos.infoSocial.info];
            updatedCardMedia[index]['hideShow'] = stat;

            return {
                infos: {
                    ...this.state.infos,
                    infoSocial: {
                        ...this.state.infos.infoSocial,
                        info: updatedCardMedia,
                    },
                },
            };
        });
    }

    //Header
    handelImgProfile(img) {
        if (img) {
            this.setState({
                infos: {
                    ...this.state.infos,
                    infoHeader: {
                        ...this.state.infos.infoHeader,
                        profil: URL.createObjectURL(img),
                    }
                }
            });
            this.setOpenCrop(true)
        }
    }
    setProfilPhoto(img) {
        this.setState({
            infos: {
                ...this.state.infos,
                infoHeader: {
                    ...this.state.infos.infoHeader,
                    profil: img,
                }
            }
        });
    }
    setCoverPhoto(img) {
        this.setState({
            infos: {
                ...this.state.infos,
                infoHeader: {
                    ...this.state.infos.infoHeader,
                    cover: img,
                }
            }
        });
    }
    handelImgCover(img) {
        if (img) {
            this.setState({
                infos: {
                    ...this.state.infos,
                    infoHeader: {
                        ...this.state.infos.infoHeader,
                        cover: URL.createObjectURL(img),
                    }
                }
            });

            this.setOpenCropCover(true)
        }
    }
    handleChangeCompleteColor = (Newcolor) => {
        this.setState({
            infos: {
                ...this.state.infos,
                infoPerso: {
                    ...this.state.infos.infoPerso,
                    color: {
                        ...this.state.infos.infoPerso.color,
                        bgColor: Newcolor.hex,
                    }
                }
            }
        });

    };
    handleChangeCompleteFontColor = (Newcolor) => {
        this.setState({
            infos: {
                ...this.state.infos,
                infoPerso: {
                    ...this.state.infos.infoPerso,
                    color: {
                        ...this.state.infos.infoPerso.color,
                        textColor: Newcolor.hex,
                    }
                }
            }
        });

    };

    //Perso
    handelChangeInputNom(NewNom) {
        this.setState({
            infos: {
                ...this.state.infos,
                infoPerso: {
                    ...this.state.infos.infoPerso,
                    nom: NewNom,
                }
            }
        })
    }
    handelChangeInputPrenom(NewPrenom) {
        this.setState({
            infos: {
                ...this.state.infos,
                infoPerso: {
                    ...this.state.infos.infoPerso,
                    prenom: NewPrenom,
                }
            }
        })
    }
    handelChangeInputDesc(NewPrenom) {
        this.setState({
            infos: {
                ...this.state.infos,
                infoPerso: {
                    ...this.state.infos.infoPerso,
                    desc: NewPrenom,
                }
            }
        })
    }

    //link
    setLinkHideShow(index, stat) {
        this.setState(prevState => {
            const updatedLinks = [...prevState.infos.infoLink];
            updatedLinks[index]['hideShow'] = stat;

            return {
                infos: {
                    ...prevState.infos,
                    infoLink: updatedLinks
                }
            };
        });
    }
    setLinkPhoto(img, index) {
        this.setState(prevState => {
            const updatedLinks = [...prevState.infos.infoLink];
            updatedLinks[index]['pic'] = img;

            return {
                infos: {
                    ...prevState.infos,
                    infoLink: updatedLinks
                }
            };
        });
    }
    handelImgLinkPic(img, index) {
        if (img) {
            this.setState(prevState => {
                const updatedLinks = [...prevState.infos.infoLink];
                updatedLinks[index]['pic'] = URL.createObjectURL(img);

                return {
                    infos: {
                        ...prevState.infos,
                        infoLink: updatedLinks
                    }
                };
            });
            this.setOpenCropLink(true, index)
        }
    }
    handelChangeLinkTitle(index, NewLink) {
        this.setState(prevState => {
            const updatedLinks = [...prevState.infos.infoLink];
            updatedLinks[index]['titre'] = NewLink;

            return {
                infos: {
                    ...prevState.infos,
                    infoLink: updatedLinks
                }
            };
        });
    }
    handelChangeLink(index, NewLink) {
        this.setState(prevState => {
            const updatedLinks = [...prevState.infos.infoLink];
            updatedLinks[index]['link'] = NewLink;

            return {
                infos: {
                    ...prevState.infos,
                    infoLink: updatedLinks
                }
            };
        });
    }
    handelChangeLinkDesc(index, NewLink) {

        this.setState(prevState => {
            const updatedLinks = [...prevState.infos.infoLink];
            updatedLinks[index]['desc'] = NewLink;

            return {
                infos: {
                    ...prevState.infos,
                    infoLink: updatedLinks
                }
            };
        });
    }
    handelClickerAddLinks() {
        this.setState({
            infos: {
                ...this.state.infos,
                infoLink: [
                    ...this.state.infos.infoLink,
                    {
                        id: uuid(),
                        pic: null,
                        type: 'post',
                        link: '',
                        titre: '',
                        desc: '',
                        color: {
                            prColor: '#000000',
                            secColor: '#ffffff',
                        },
                        hideShow: true,
                    }
                ]
            }
        });
        return
    }
    handleChangeTypeLink(index, type) {
        this.setState(prevState => {
            const updatedLinks = [...prevState.infos.infoLink];
            updatedLinks[index]['type'] = type

            return {
                infos: {
                    ...prevState.infos,
                    infoLink: updatedLinks
                }
            };
        });
    }
    handelClickerDeleteLinks(idx) {
        const updatedInfoLink = [...this.state.infos.infoLink];
        updatedInfoLink.splice(idx, 1);
        this.setState({
            infos: {
                ...this.state.infos,
                infoLink: updatedInfoLink,
            }
        });
        return
        return
    }
    handeleChangeLinkPrimaryColor = (NewColor, index) => {
        this.setState(prevState => {
            const updatedLinks = [...prevState.infos.infoLink];
            updatedLinks[index]['color']['prColor'] = NewColor.hex

            return {
                infos: {
                    ...prevState.infos,
                    infoLink: updatedLinks
                }
            };
        });

    }
    handeleChangeLinkSecondaryColor = (NewColor, index) => {

        this.setState(prevState => {
            const updatedLinks = [...prevState.infos.infoLink];
            updatedLinks[index]['color']['secColor'] = NewColor.hex
            return {
                infos: {
                    ...prevState.infos,
                    infoLink: updatedLinks
                }
            };
        });
    }

    //Save
    handelClickSave() {
        localStorage.removeItem('myState')
    }





    render() {
        let item = 0

        localStorage.setItem('myState', JSON.stringify(this.state));

        document.title = "AjCrt | Skote - React Admin & Dashboard Template";

        if (this.state.openCrop) {
            return (
                <CropEasy
                    photo={this.state.infos.infoHeader.profil}
                    setOpenCrop={this.setOpenCrop}
                    setPhoto={this.setProfilPhoto}
                />
            )
        } else if (this.state.openCropCover) {
            return (
                <CropEasyCover
                    photo={this.state.infos.infoHeader.cover}
                    setOpenCrop={this.setOpenCropCover}
                    setPhoto={this.setCoverPhoto}
                />
            )
        } else if (this.state.openCropLink.stat) {
            return (
                <CropLink
                    photo={this.state.infos.infoLink[this.state.openCropLink.index].pic}
                    setOpenCrop={this.setOpenCropLink}
                    setPhoto={this.setLinkPhoto}
                    index={this.state.openCropLink.index}
                />
            )
        }
        else {
            return (
                <React.Fragment>
                    <div className="page-content">
                        <Container fluid>
                            <Breadcrumbs title="Cartes" breadcrumbItem={`${this.state.pageTitle} Carte`} />
                            <Row>
                                <Col md="8">
                                    <Row>
                                        <Col md={{ size: 10, offset: 1 }}>
                                            <Row className="text-center">
                                                <Col>
                                                    <Card className="bg-primary">
                                                        <button className="btn text-light fs-5" onClick={this.handelClickerAddContact}>
                                                            <i className="mdi mdi-phone me-2" />Add Contact
                                                        </button>
                                                    </Card>
                                                </Col>
                                                <Col>
                                                    <Card className="bg-secondary">
                                                        <button className="btn text-light fs-5" onClick={this.handelClickerAddSocial}>
                                                            <i className="mdi mdi-cellphone-nfc me-2" />Add Social
                                                        </button>
                                                    </Card>
                                                </Col>
                                                <Col>
                                                    <Card className="bg-primary">
                                                        <button className="btn text-light fs-5" onClick={this.handelClickerAddLinks}>
                                                            <i className="mdi mdi-link-variant me-2" />Add Links
                                                        </button>
                                                    </Card>
                                                </Col>
                                            </Row>
                                            <CarteHeader infoHeader={this.state.infos.infoHeader}
                                                onChangeProfil={this.handelImgProfile}
                                                onChnageCover={this.handelImgCover}
                                            />
                                            <Card className="d-flex">
                                                <Row>
                                                    <Col>
                                                        <CardBody className="px-2">
                                                            Background
                                                            <CirclePicker
                                                                color={this.state.infos.infoPerso.color.bgColor}
                                                                width="100%"
                                                                className="sitecolor pt-2 px-3 border border-2 rounded-3"
                                                                colors={this.state.colors}
                                                                onChangeComplete={this.handleChangeCompleteColor}
                                                            />
                                                        </CardBody>
                                                    </Col>
                                                    <Col>
                                                        <CardBody className="px-2">
                                                            Font
                                                            <CirclePicker
                                                                color={this.state.infos.infoPerso.color.textColor}
                                                                width="100%"
                                                                className="sitecolor pt-2 px-3 border border-2 rounded-3"
                                                                colors={this.state.colors}
                                                                onChangeComplete={this.handleChangeCompleteFontColor}

                                                            />
                                                        </CardBody>
                                                    </Col>
                                                </Row>
                                            </Card>
                                            <CarteInfoPerso
                                                info={this.state.infos.infoPerso}
                                                onChangeInputNom={this.handelChangeInputNom}
                                                onChangeInputPrenom={this.handelChangeInputPrenom}
                                                onChangeInputDesc={this.handelChangeInputDesc}
                                            />

                                            {this.state.infos.infoContact.contactExist
                                                ? <CarteContacts
                                                    onDelete={this.handelClickerDeleteContact}
                                                    contactColor={this.state.infos.infoContact.color}
                                                    colors={this.state.colors}
                                                    onChangePrimary={this.handeleChangeContactPrimaryColor}
                                                    onChangeSecondary={this.handeleChangeContactSecondaryColor}
                                                    infoContact={this.state.infos.infoContact}
                                                    onChangeNom={this.handelChangeContactNom}
                                                    onChangePrenom={this.handelChangeContactPrenom}
                                                    onChangeTel={this.handelChangeContactTel}
                                                    onChangeFix={this.handelChangeContactFix}
                                                    onChangeEmail={this.handelChangeContactEmail}
                                                    onChangeWeb={this.handelChangeContactWeb}
                                                    setHideShow={this.setContactHideShow}
                                                />
                                                : ''
                                            }
                                            {
                                                this.state.infos.infoSocial.socialExist
                                                    ? <CarteSocial
                                                        colors={this.state.colors}
                                                        onDelete={this.handelClickerDeleteSocial}
                                                        listMedia={this.state.listMedia} cardMedia={this.state.infos.infoSocial.info}
                                                        onClickAddMedia={this.handelClickerAddMedia}
                                                        onClickDeleteMedia={this.handelClickerDeleteMedia}
                                                        mediaColor={this.state.infos.infoSocial.color}
                                                        onChangeSecondary={this.handeleChangeMediaSecondaryColor}
                                                        onChangePrimary={this.handeleChangeMediaPrimaryColor}
                                                        onChangeLink={this.handelChangeMediaLink}
                                                        setHideShow={this.setSocialHideShow}
                                                        hideShow={this.state.socialHideShow}
                                                        setMediaHideShow={this.setMediaHideShow}
                                                    />
                                                    : ''
                                            }
                                            <DragDropContext onDragEnd={this.handelOnDragEnd} >
                                                <Droppable droppableId="links" type="group">
                                                    {(provided) => (
                                                        <div className="links" ref={provided.innerRef} {...provided.droppableProps}>
                                                            {this.state.infos.infoLink.map((link, index) => (
                                                                <Draggable key={link.id} draggableId={link.id} index={index}>
                                                                    {(provided) => (
                                                                        <div
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                            ref={provided.innerRef}
                                                                        >
                                                                            <CarteLink
                                                                                index={index}
                                                                                idx={index}
                                                                                onDelete={this.handelClickerDeleteLinks}
                                                                                infoLink={link}
                                                                                onChangeTitle={this.handelChangeLinkTitle}
                                                                                onChangeDesc={this.handelChangeLinkDesc}
                                                                                onChangeLink={this.handelChangeLink}
                                                                                colors={this.state.colors}
                                                                                color={link.color}
                                                                                onChangeSecondary={this.handeleChangeLinkSecondaryColor}
                                                                                onChangePrimary={this.handeleChangeLinkPrimaryColor}
                                                                                onChangePic={this.handelImgLinkPic}
                                                                                setHideShow={this.setLinkHideShow}
                                                                                onChangeType={this.handleChangeTypeLink}
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </Draggable>
                                                            ))}
                                                            {provided.placeholder}
                                                        </div>
                                                    )}
                                                </Droppable>
                                            </DragDropContext>





                                            {/* {this.state.infos.cartes.map((carte, index) => {
                                                if (carte === 'c') {
                                                    return (
                                                        <CarteContacts key={index} index={index}
                                                            onDelete={this.handelClickerDeleteContact}
                                                            contactColor={this.state.infos.infoContact.color}
                                                            colors={this.state.colors}
                                                            onChangePrimary={this.handeleChangeContactPrimaryColor}
                                                            onChangeSecondary={this.handeleChangeContactSecondaryColor}                                                            
                                                            infoContact={this.state.infos.infoContact}
                                                            onChangeNom={this.handelChangeContactNom}
                                                            onChangePrenom={this.handelChangeContactPrenom}
                                                            onChangeTel={this.handelChangeContactTel}
                                                            onChangeFix={this.handelChangeContactFix}
                                                            onChangeEmail={this.handelChangeContactEmail}
                                                            onChangeWeb={this.handelChangeContactWeb}
                                                            setHideShow={this.setContactHideShow}
                                                        />
                                                    )
                                                }
                                                if (carte === 's') {
                                                    return (
                                                        <CarteSocial key={index} index={index} colors={this.state.colors}
                                                            onDelete={this.handelClickerDeleteSocial}
                                                            listMedia={this.state.listMedia} cardMedia={this.state.infos.infoSocial.info}
                                                            onClickAddMedia={this.handelClickerAddMedia}
                                                            onClickDeleteMedia={this.handelClickerDeleteMedia}
                                                            mediaColor={this.state.infos.infoSocial.color}
                                                            onChangeSecondary={this.handeleChangeMediaSecondaryColor}
                                                            onChangePrimary={this.handeleChangeMediaPrimaryColor}
                                                            onChangeLink={this.handelChangeMediaLink}
                                                            setHideShow={this.setSocialHideShow}
                                                            hideShow={this.state.socialHideShow}
                                                            setMediaHideShow={this.setMediaHideShow}
                                                        />

                                                    )
                                                }
                                                if (carte === 'l') {
                                                    item = item + 1
                                                    return (
                                                        <CarteLink key={index} index={index} idx={item - 1}
                                                            onDelete={this.handelClickerDeleteLinks}
                                                            infoLink={this.state.infos.infoLink[item - 1]}
                                                            onChangeTitle={this.handelChangeLinkTitle}
                                                            onChangeDesc={this.handelChangeLinkDesc}
                                                            onChangeLink={this.handelChangeLink}
                                                            colors={this.state.colors}
                                                            color={this.state.infos.infoLink[item - 1].color}
                                                            onChangeSecondary={this.handeleChangeLinkSecondaryColor}
                                                            onChangePrimary={this.handeleChangeLinkPrimaryColor}
                                                            onChangePic={this.handelImgLinkPic}
                                                            setHideShow={this.setLinkHideShow}
                                                        />
                                                    )
                                                }
                                            })} */}

                                        </Col>
                                    </Row>
                                </Col>
                                <Col md="4">
                                    <Card>
                                        <CardBody className="mx-5">
                                            <CartePreview
                                                infoPerso={this.state.infos.infoPerso}
                                                infoHeader={this.state.infos.infoHeader}
                                                contactExist={this.state.infos.infoContact.contactExist}
                                                socialExist={this.state.infos.infoSocial.socialExist}
                                                cardMedia={this.state.infos.infoSocial.info}
                                                contactColor={this.state.infos.infoContact.color}
                                                mediaColor={this.state.infos.infoSocial.color}
                                                linkColor={this.state.linkColor}
                                                infoContact={this.state.infos.infoContact}
                                                infoLink={this.state.infos.infoLink}
                                                onClickSave={this.handelClickSave}
                                                socialHideShow={this.state.socialHideShow}

                                            />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </React.Fragment>
            )
        }
    }
}



export default AjouterOrder;
