import React, { Component } from "react";
import logo from '../../../../assets/images/212Agency.png'

import phone from '../../../../assets/images/iphone.png'
import profil_vide from '../../../../assets/images/profile-vide.webp'
import avatar1 from "../../../../assets/images/users/avatar-2.jpg";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";

import './styleCss.css'

import {
    Table,
    CardTitle,
    Label,
    Card,
    CardBody,
    Row,
    Col,
    Accordion,
    AccordionItem,
    AccordionBody,
    AccordionHeader,
    CardFooter,
} from "reactstrap";


//Import Breadcrumb
class CartePreview extends Component {
    constructor(props) {
        super(props);
        this.node = React.createRef()
        const storedState = localStorage.getItem('myState');
        this.state = this.state = storedState ? JSON.parse(storedState) : {}

        this.handelClickSave = this.handelClickSave.bind(this)
    }

    handelClickSave() {
        this.props.onClickSave()
    }


    render() {




        return (

            <React.Fragment>
                <div className="mt-2 d-flex justify-content-center" style={{ position: 'relative' }} >
                    <img
                        style={{
                            height: '480px',
                            position: 'absolute',
                            left: '5px',
                            top: '-15px',

                        }}
                        src={phone}
                        alt=""
                    />

                    <Card className="preview-Card d-flex flex-column justify-content-start  align-self-center" style={{
                        height: '450px', width: '215px', overflow: 'scroll', backgroundColor: this.props.infoPerso.color.bgColor, borderRadius: "35px",
                        zIndex: '1',
                    }}>
                        <div className="d-flex justify-content-center"
                            style={{
                                borderRadius: "25px 25px 0 0", height: '30px', height: '30%',
                                backgroundImage: `url(${this.props.infoHeader.cover})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center',
                            }}
                        >
                            <div className="px-5 pt-5 mb-3">
                                <div className="avatar-md  ">
                                    <div className="img-display-after">
                                        {this.props.infoHeader.profil
                                            ? <img
                                                src={this.props.infoHeader.profil}
                                                alt=""
                                                className="img-fluid "
                                            />
                                            : <img
                                                src={profil_vide}
                                                alt=""
                                                className="img-fluid "
                                            />
                                        }
                                    </div>

                                </div>
                                <div>
                                </div>
                            </div>
                        </div>
                        <div className="preview-content">
                            <div className="px-2">
                                <h4 className="text-center mt-1 ">
                                    <span style={{ color: this.props.infoPerso.color.textColor }}>{this.props.infoPerso.nom}</span>{" "}
                                    <span style={{ color: this.props.infoPerso.color.textColor }}>{this.props.infoPerso.prenom}</span>
                                </h4>
                                <div className="text-center fs-6"><p style={{ color: this.props.infoPerso.color.textColor }}>{this.props.infoPerso.desc}</p></div>
                            </div>

                            {this.state.infos.infoContact.contactExist
                                ? <div>
                                    {
                                        this.props.infoContact.hideShow
                                        ? <div className="preview-contact  mb-3 d-flex flex-column gap-3">
                                            <div className="d-flex justify-content-center gap-3">
                                                {this.props.infoContact.tel ? <button className="btn p-1"><i style={{ color: this.props.contactColor.prColor }} className="mdi mdi-phone" /></button> : null}
                                                {this.props.infoContact.fix ? <button className="btn p-1"><i style={{ color: this.props.contactColor.prColor }} className="mdi mdi-phone-classic" /></button> : null}
                                                {this.props.infoContact.email ? <button className="btn p-1"><i style={{ color: this.props.contactColor.prColor }} className="mdi mdi-email" /></button> : null}
                                                {this.props.infoContact.web ? <button className="btn p-1"><i style={{ color: this.props.contactColor.prColor }} className="mdi mdi-web" /></button> : null}
                                            </div>
                                            <button className="btn mx-2"
                                                style={{ backgroundColor: this.props.contactColor.secColor, color: this.props.contactColor.prColor,border: `${this.props.contactColor.prColor} 2px solid` }}>
                                                <i className="mdi mdi-human-greeting-proximity me-2 rounded-3" style={{ color: this.props.contactColor.prColor }} />
                                                Enregistrer le Contact
                                            </button>
                                        </div>
                                        : ''
                                    }
                                    </div>
                                : ''
                            }


                            {this.props.socialHideShow
                                ? <div className="mb-2 d-flex flex-wrap justify-content-center gap-2 px-3">
                                    {this.props.cardMedia.map((media, index) => {
                                        return (
                                            media.hideShow
                                                ? <button key={index} className="btn btn-outline-dark rounded-3 btn-sm "
                                                    style={{ backgroundColor: this.props.mediaColor.secColor, border: `${this.props.mediaColor.prColor} 2px solid` }}
                                                >
                                                    <i className={`mdi mdi-${media.logo}`}
                                                        style={{ color: this.props.mediaColor.prColor }}
                                                    />
                                                </button>
                                                : ''
                                        )
                                    })}
                                </div>
                                : ''
                            }
                            {this.props.infoLink.map((link, index) => {
                                return (
                                    link.hideShow
                                        ? link.type === 'post'
                                            ? <div style={{
                                                border: `1px solid ${link.color.prColor}`,
                                                backgroundColor: link.color.secColor,
                                                borderRadius: '10px',
                                            }} key={index} className="mx-2 mb-2 p-1 link">
                                                <div className="d-flex  justify-content-around align-items-center gap-1" >
                                                    <div className="align-self-center">
                                                        <div className="avatar-xs rounded-3 link-pic">
                                                            <img
                                                                src={link.pic ? link.pic : profil_vide}
                                                                alt=""
                                                                className="img-fluid rounded-3"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="d-flex flex-column  " style={{ width: "75%" }} >
                                                        <div style={{ color: link.color.prColor }} className="fw-bold">{link.titre ? link.titre : 'Ttile'}</div>
                                                        <div style={{ color: link.color.prColor, wordWrap: 'break-word', }}>{link.desc ? link.desc : 'Description'}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            : <div className="youtube-box mx-2 mb-2 ">
                                                <ReactPlayer className='video' url={link.link ? link.link :'https://youtu.be/dQw4w9WgXcQ'} controls width='100%' height='150px' />
                                            </div>
                                        : ''
                                )
                            })}
                        </div>
                        <CardFooter className="text-center mt-auto" >
                            <img src={logo} style={{ width: "2rem" }} alt="" />
                        </CardFooter>
                    </Card>
                </div>

                <div className="text-center d-flex justify-content-around ">
                    <Link to="/ecommerce-cartes/ajouter-carte/your-card">
                        <button className="fw-bold btn btn-outline-success px-3">
                            Preview
                        </button>
                    </Link>
                    <button onClick={this.handelClickSave} className="fw-bold btn btn-outline-primary px-3">
                        Save
                    </button>
                </div>
            </React.Fragment>
        );
    }
}



export default CartePreview;
